<template>
  <div :class="modalClasses">
    <div class="flex flex-col gap-s24">
      <div class="headline-small">
        {{$t('email.email_verified')}}
      </div>
    
      <div class="body-text-large">
        {{$t('email.thanks_for_verifying')}}
      </div>
    </div>

    <ButtonV2
      @onClick="closeModal"
      testId="btn-close"
      :label="$t('navigation.close')"
      size="large"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'ConfirmEmail',
  components: {
    ButtonV2
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    modalClasses(){
      return {
        'flex flex-col gap-s40': true,
        'py-s40 px-s24 email-container--desktop': !this.isMobileDevice,
        'p-s20 email-container--mobile justify-between': this.isMobileDevice,
      };
    }
  },
  methods: {
    closeModal(){
      if (this.isAuthenticated()) {
        return this.showModal('KycStatus');
      }

      this.hideModal();
    }
  }
};
</script>

<style scoped>
.email-container--desktop {
  min-width: 478px;
}
</style>
